<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Axios from "@/libraries/Axios";
import ApiUrl from "@/libraries/ApiUrl";

export default {
    name: "MixinsNotifications",
    data() {
        return {
            permissionStatus: null,
            isSubscribing: false,
            steps: {
                mobile: {
                    ios: [
                        {text: this.$t("กดปุ่ม Share"), icon: "mdi-export-variant", after: true},
                        {text: this.$t("เพิ่มไปยังหน้าจอโฮม"), icon: "mdi-plus-box-outline"},
                        {text: this.$t("เพิ่ม"), icon: 'mdi-check'}
                    ],
                    android: [
                        {text: this.$t('กดที่ปุ่มเมนูของ Chrome'), icon: "mdi-dots-vertical", after: true},
                        {text: this.$t("เพิ่มไปยังหน้าจอโฮม"), icon: "mdi-cellphone-arrow-down-variant"},
                        {text: this.$t("เพิ่ม"), icon: 'mdi-check'}
                    ]
                },
                desktop: {
                    macos: {
                        chrome: [
                            // {text: this.$t('คลิกที่ปุ่ม 3 จุดบนเมนู'), icon: "mdi-dots-vertical", after: true},
                            // {text: this.$t('แคสต์ บันทึก และแชร์'), icon: "mdi-file-download-outline"},
                            // {text: this.$t('ติดตั้ง') + " Lnw.Me...", icon: "logo"}
                            {text: this.$t('คลิกที่ปุ่มหน้าจอลูกศรลงบน Address Bar'), icon: "mdi-monitor-arrow-down-variant", after: true},
                            {text: this.$t('ติดตั้ง') + " Lnw.Me...", icon: "logo"},
                            {text: this.$t('เรียบร้อย'), icon: "mdi-check", after: true}
                        ],
                        safari: [
                            {text: this.$t("กดปุ่ม Share"), icon: "mdi-export-variant", after: true},
                            {text: this.$t("เพิ่มไปยัง Dock"), icon: "mdi-dock-bottom"},
                            {text: this.$t("เพิ่ม"), icon: 'mdi-check'}
                        ]
                    },
                    windows: {
                        chrome: [
                            {text: this.$t('คลิกที่ปุ่ม 3 จุดบนเมนู'), icon: "mdi-dots-vertical", after: true},
                            {text: this.$t('แคสต์ บันทึก และแชร์'), icon: "mdi-file-download-outline"},
                            {text: this.$t('ติดตั้ง') + " Lnw.Me...", icon: "logo"}
                        ]
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            cpFlagIsLogin: 'user/isLogin',
            subscribedDeviceTokenID: 'user/subscribedDeviceTokenID',
        }),
        ...mapState({
            isFetchedWebPushTokens: state => state.user.called.fetchWebPushTokens,
            web_push_tokens: state => state.user.web_push_tokens,
            subscriptionStatus: state => state.user.subscriptionStatus,
            activeDevice: state => state.user.activeDevice,
        }),
        isSupportSW(){
            return this.permissionStatus != 'not_supported';
        },
        hasWebPushTokens(){
            return this.web_push_tokens && this.web_push_tokens.length > 0
        },
        computedSteps(){
            const {device, os, browser} = this.activeDevice;

            if(device === "mobile"){
                return this.steps.mobile[os] || [];
            }else if(device === "desktop"){
                return this.steps.desktop[os]?.[browser] || [];
            }
            return [];
        },
    },

    methods: {
        ...mapActions({
            actionFetchWebPushTokens: "user/fetchWebPushTokens",
            actionSetSubscriptionStatus: "user/setSubscriptionStatus",
            actionDetectEnvironment: "user/detectEnvironment"
        }),
        registerWebPushNotification(){
            let that = this
            if("serviceWorker" in navigator && "PushManager" in window && "Notification" in window){
                // ขอสิทธิ์จากผู้ใช้สำหรับการแสดง Notification
                Notification.requestPermission().then((permission) => {
                    if(permission === "granted"){
                        navigator.serviceWorker.register("/service-worker.js").then(async(registration) => {
                            try {
                                that.isSubscribing = true;
                                const vapidPublicKey = (await Axios.get(ApiUrl.lnwmsg("guest/vapid_public_key"))).data;
                                const convertedVapidKey = this.urlBase64ToUint8Array(vapidPublicKey)

                                const subscription = await registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: convertedVapidKey
                                });

                                await Axios.post(ApiUrl.lnwmsg("auth/add_token"), {
                                    platform: 'pwa_lnwme',
                                    role: 'any',
                                    device_token: JSON.stringify(subscription),
                                });
                                await this.actionSetSubscriptionStatus(subscription);
                                await this.actionFetchWebPushTokens();

                                if(typeof this.updateCheckbox != 'undefined'){
                                    this.updateCheckbox();
                                }
                                that.isSubscribing = false;
                            } catch(e) {
                                alert(`error: ${e}`);
                            }
                        }).catch(error => alert("Service Worker registration failed:" + error));
                    }else{
                        alert(this.$t('คุณยังไม่ได้อนุญาตให้แอพรับ Notification คุณสามารถเปิดได้ใน Settings'));
                    }
                });
            }else{
                alert("Service Workers are not supported in this browser.");
            }
        },
        async unsubscribeWebPushNotification(all = false){
            if(confirm( this.$t('คุณแน่ใจที่จะยกเลิกการรับการแจ้งเตือน' +( (all == true)?'ทั้งหมด':'') + 'หรือไม่ ?'))){
                if('serviceWorker' in navigator && "PushManager" in window){
                    try {
                        const registration = await navigator.serviceWorker.ready;
                        const subscription = await registration.pushManager.getSubscription();
                        if(subscription && this.subscribedDeviceTokenID){

                            let data = {
                                platform: 'pwa_lnwme',
                                device_token_id: this.subscribedDeviceTokenID,
                            };
                            if(all == true){
                                data.remove_all = true;
                            }
                            await Axios.post(ApiUrl.lnwmsg("auth/remove_token"), data);

                            await this.actionSetSubscriptionStatus(null);
                            await this.actionFetchWebPushTokens();
                            // Unsubscribe the push subscription
                            await subscription.unsubscribe();
                            if(typeof this.updateCheckbox != 'undefined'){
                                this.updateCheckbox();
                            }

                        }else{
                            alert('You are not subscribed to notifications.');
                        }
                    } catch(e) {
                        alert(`Failed to unsubscribe: ${e}`);
                    }
                }else{
                    alert("Service Workers are not supported in this browser.");
                }
            }
        },
        // แปลง VAPID Key ให้อยู่ในรูปแบบ Uint8Array
        urlBase64ToUint8Array(base64String){
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, "+")
                .replace(/_/g, "/");
            const rawData = window.atob(base64);
            return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
        },

        checkServiceWorker(){
            if(this.permissionStatus == null && 'serviceWorker' in navigator && "Notification" in window){
                navigator.serviceWorker.register("/service-worker.js").then((registration) => {
                    this.permissionStatus = Notification.permission;

                    registration.pushManager.getSubscription().then(subscription => {
                        
                        this.actionSetSubscriptionStatus(subscription);
                        
                    });

                    // Check if there's an updated service worker waiting to be activated
                    if(registration.waiting){
                        this.$emit('force-reload');
                    }

                    // Listen for updates to the service worker
                    registration.onupdatefound = () => {
                        const newWorker = registration.installing;

                        newWorker.onstatechange = () => {
                            if(newWorker.state === 'installed' && navigator.serviceWorker.controller){
                                // New service worker is installed and waiting, emit event to parent
                                this.$emit('force-reload');
                            }
                        };
                    };

                }).catch(error => {
                    alert("Service Worker registration failed:" + error);
                });
            }else{
                this.permissionStatus = 'not_supported';
            }
        },
    },
    mounted(){
        this.checkServiceWorker();
        this.actionDetectEnvironment();
    },
    watch : {
        cpFlagIsLogin(newValue) {
            if(newValue){
                this.actionFetchWebPushTokens();
            }
        }
    }
}
</script>
<template>
    <div></div>
</template>